.user-card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative;
  width: 60vw;
  height: 80vh;
  border-radius: 15px;
  background-color: lightslategrey;
}
.user-card__form__footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 20px;
  width: 100%;
}
