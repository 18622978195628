.input-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.input-group__label {
  margin-right: 12px;
}

.input-group__input {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.input-group__alert {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  font-size: 13px;
  color: orangered;
}

.input-group--error input {
  border-color: orangered;
}
