.button {
  appearance: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  min-width: 5ch;
  width: 80px;
  height: 40px;
  border: none;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  text-decoration: none;
  font-weight: bold;
  padding: 0;
  text-decoration: none;
  user-select: none;
  transition: all 300ms ease-in-out;
  border-radius: 4px;
}

.button[aria-pressed="true"],
.button:hover {
  cursor: pointer;
}
 {
}

.button:disabled,
.button[aria-disabled="true"] {
  cursor: not-allowed !important;
  pointer-events: none;
  background-color: #016101 !important;
  textcolor: green !important;
}
 {
}

.button:focus,
.button:active {
  outline: none;
}
